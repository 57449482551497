<template>
  <form @submit.prevent="handleSubmit">
    <div class="content mt-5">
      <p class="text-color">Choose the type of user you want to create:</p>
      <div id="options" class="ml-2 mt-5">
        <div class="p-3 px-5 bg-brightGray w-full md:w-2/5">
          <input type="radio" id="delegate" name="user-option" checked />
          <label class="ml-2 text-color" for="delegate">Delegate</label>
        </div>
      </div>
      <div class="form__div mt-8">
        <input
          type="text"
          class="form__input"
          placeholder=" "
          v-model="email"
          required
        />
        <label class="form__label">Email</label>
      </div>
    </div>
    <div class="px-3 my-3 flex justify-between">
      <a type="button" class="px-4 py-2 text-white bg-darkBlue" href="#">
        Cancel
      </a>
      <button
        type="submit"
        class="px-4 py-2 create-btn bg-mindaro text-darkBlue"
      >
        <em v-if="processingRegister" class="fa fa-spinner fa-spin mr-2"></em
        >Create
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, defineEmits, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const email = ref(null);
const processingRegister = ref(false);

const user = computed(() => store.getters["user/getUserData"]);

const emit = defineEmits(["registerUserEvent"]);

function handleSubmit() {
  processingRegister.value = true;
  const role = "location_delegate";

  const data = {
    location: user.value.location,
    role: role,
    email: email.value,
    locationUser: false,
  };

  emit("registerUserEvent", data);
}

</script>

<style scoped>
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15em solid #fff;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  transform: scale(0);
  border: 0.4em solid #1d2e48;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em red;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
</style>
